.cell {
  padding: 0.75rem 0.5rem 0.75rem 0.5rem;
}

.headerWrapper {
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

.tableRow {
  cursor: pointer;
  transition-duration: 250ms;
  transition-property: background, box-shadow;
  border-radius: 10px;
  background-color: #f8fafc;
}

.tableRow:hover {
  background: rgba(109, 181, 87, 0.05);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}

.tableRow:hover td {
  border-top: 1px solid rgba(109, 181, 87, 0.5);
  border-bottom: 1px solid rgba(109, 181, 87, 0.5);
}

.tableRow:hover td:first-child {
  border-left: 1px solid rgba(109, 181, 87, 0.5);
}

.tableRow:hover td:last-child {
  border-right: 1px solid rgba(109, 181, 87, 0.5);
}

.tableRow td {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.tableRow td:first-child {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}

@screen md {
  .tableRow td:first-child {
    border-right: 0;
  }
  .cell {
    padding: 0.5rem 1rem 0.5rem 1rem;
  }

  .tableRow td:nth-child(5) {
    border-right: 1px solid #eee;
  }

  .tableRow:hover td:nth-child(5) {
    border-right: 1px solid rgba(109, 181, 87, 0.5);
  }
}

@screen lg {
  .tableRow td:nth-child(5) {
    border-right: 0;
  }

  .tableRow:hover td:nth-child(5) {
    border-right: 0;
  }
}

@screen xl {
  .tableRow td {
    min-width: 203.5px;
  }
}

.tableRow td:last-child {
  border-right: 1px solid #eee;
}

.heroImage {
  background: url('../images/pageBg.png');
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
}

.table {
  border-collapse: separate;
  border-spacing: 0 8px;
  width: 100%;

  thead {
    color: #000;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    display: none;

    tr th {
      padding: 8px;
      border-radius: 4px;
      padding-left: 16px;

      @screen md {
        padding: 18px;
        border-radius: 0px;
      }
    }

    th:nth-child(n + 2) {
      display: none;
    }

    @screen md {
      display: table-header-group;

      th:nth-child(n + 2) {
        display: table-cell;
      }

      th:last-child {
        display: none;
      }

      font-size: 16px;
      border-radius: 8px;
    }

    @screen lg {
      th:last-child {
        display: table-cell;
      }
    }
  }

  td:nth-child(n + 2) {
    display: none;
  }

  td:first-child,
  th:first-child {
    border-radius: 8px;
  }

  @screen md {
    td:nth-child(n + 2) {
      display: table-cell;
    }

    td:last-child {
      display: none;
    }

    td:first-child,
    th:first-child {
      padding-left: 40px;
      border-radius: 10px 0 0 10px;
    }

    td:last-child,
    th:last-child {
      border-radius: 0 10px 10px 0;
    }

    td:nth-child(5),
    th:nth-child(5) {
      border-radius: 0 10px 10px 0;
    }
  }

  @screen lg {
    td:last-child {
      display: table-cell;
    }
    td:nth-child(5),
    th:nth-child(5) {
      border-radius: 0;
    }
  }
}

.radioContainer {
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.75rem;
  cursor: pointer;

  @screen sm {
    gap: 0.5em;
  }
}

.customRadio {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: 16px;
  height: 16px;
  border: 0.15rem solid #d9d9d9;
  border-radius: 50%;
  transform: translateY(-0.075rem);

  display: grid;
  place-content: center;
  margin-top: auto;
  margin-bottom: auto;
}

.customRadio::before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1rem 1rem #6db557;
  background-color: #6db557;
}

.customRadio:checked::before {
  transform: scale(1);
}

.customCheckbox {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fdfdfd;
  margin: 0;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #d9d9d9;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  box-shadow: 0px 3.3375px 3.3375px 0px rgba(0, 0, 0, 0.1) inset;
  display: grid;
  place-content: center;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
}

.customCheckbox::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #6db557;
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

.customCheckbox:checked::before {
  transform: scale(1);
}
