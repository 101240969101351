@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

p,
h1,
h2,
h3,
a {
  font-family: 'Inter', sans-serif;
}

.section .fp-tableCell {
  display: block;
}

body .fp-right#fp-nav {
  right: 3vw;
}

body .hideMenu.fp-right#fp-nav {
  display: none !important;
}

.fp-right#fp-nav ul li {
  margin: 50px 7px;
}

.section.multi-color .fp-controlArrow {
  top: 85%;
}

.fp-controlArrow .fp-prev {
  left: 15%;
}
.fp-controlArrow .fp-next {
  left: 30%;
}

.fp-controlArrow {
  display: none;
}

.fp-viewing-0 .fp-right#fp-nav ul li a::before,
.fp-viewing-4-0 .fp-right#fp-nav ul li a::before,
.fp-viewing-4-1 .fp-right#fp-nav ul li a::before,
.fp-viewing-4-2 .fp-right#fp-nav ul li a::before,
.fp-viewing-4-3 .fp-right#fp-nav ul li a::before,
.fp-viewing-4-4 .fp-right#fp-nav ul li a::before,
.fp-viewing-4-5 .fp-right#fp-nav ul li a::before,
.fp-viewing-4-6 .fp-right#fp-nav ul li a::before,
.fp-viewing-4-7 .fp-right#fp-nav ul li a::before {
  color: whitesmoke;
}

.fp-viewing-0 .fp-right#fp-nav ul li a::before,
.fp-right#fp-nav ul li a:hover::before,
.fp-right#fp-nav:hover ul li a::before {
  display: block;
}

.fp-right#fp-nav ul li a:hover,
.fp-right#fp-nav ul li a:hover::after,
.fp-right#fp-nav ul li a:hover::before {
  border-color: #51b749 !important;
  color: #51b749 !important;
}
.fp-right#fp-nav ul li a:hover span {
  background-color: #51b749 !important;
}

.fp-right#fp-nav ul li a::before {
  color: #08293d;
  position: absolute;
  right: 30px;
  top: -7px;
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;
  letter-spacing: 2.7px;
  font-weight: 300;
  font-size: 0.9em;
  line-height: 27px;
  display: none;
}

.section .fixed-header {
  display: block;
}
.fp-viewing-4-0 .fixed-header,
.fp-viewing-4-1 .fixed-header,
.fp-viewing-4-2 .fixed-header,
.fp-viewing-4-3 .fixed-header,
.fp-viewing-4-4 .fixed-header,
.fp-viewing-5-0 .fixed-header,
.fp-viewing-5-1 .fixed-header,
.fp-viewing-5-2 .fixed-header,
.fp-viewing-5-3 .fixed-header {
  display: fixed;
}

@keyframes appear {
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.fp-right {
  animation-duration: 3s;
  animation-name: appear;
  animation-iteration-count: 1;
  animation-delay: 2s;
  animation-fill-mode: both;
}

.fp-controlArrow {
  top: 75% !important;
}
.fp-controlArrow.fp-prev {
  left: 15% !important;
}
.fp-controlArrow.fp-next {
  left: 26% !important;
}

.fp-right#fp-nav ul li a.active ~ .fp-right#fp-nav ul li a {
  color: green !important;
}

.fp-right#fp-nav ul li a.active::before {
  font-weight: 600;
  display: block;
  top: -4px;
  right: 35px;
}

.fp-right#fp-nav ul li:nth-child(1) a::before {
  content: 'Home';
}

.fp-right#fp-nav ul li:nth-child(2) a::before {
  content: 'About';
}

.fp-right#fp-nav ul li:nth-child(3) a::before {
  content: 'Products';
}

.fp-right#fp-nav ul li:nth-child(4) a::before {
  content: 'Portfolio';
}

.fp-right#fp-nav ul li:nth-child(5) a::before {
  content: 'Performance';
}

.fp-right#fp-nav ul li:nth-child(6) a::before {
  content: 'Process';
}

.fp-right#fp-nav ul li:nth-child(7) a::before {
  content: 'Team';
}

.fp-right#fp-nav ul li:nth-child(8) a::before {
  content: 'Contact';
}
.fp-viewing-0 .fp-right#fp-nav ul li span,
.fp-viewing-4-0 .fp-right#fp-nav ul li span,
.fp-viewing-4-1 .fp-right#fp-nav ul li span,
.fp-viewing-4-2 .fp-right#fp-nav ul li span,
.fp-viewing-4-3 .fp-right#fp-nav ul li span,
.fp-viewing-4-4 .fp-right#fp-nav ul li span,
.fp-viewing-4-5 .fp-right#fp-nav ul li span,
.fp-viewing-4-6 .fp-right#fp-nav ul li span,
.fp-viewing-4-7 .fp-right#fp-nav ul li span {
  background: whitesmoke;
}

.fp-right#fp-nav ul li a.active span:last-child {
  display: none;
}

.fp-right#fp-nav ul li a.active {
  border: 3px solid #000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-left: -6px;
  top: -8px;
  position: relative;
}
.fp-right#fp-nav ul li a.active::after {
  color: #000;
  font-weight: bold;
  font-size: 25px;
  line-height: 10px;
  border: none;
  top: 3.5px;
  position: absolute;
  left: 2px;
}

.fp-viewing-0 .fp-right#fp-nav ul li a.active::after,
.fp-viewing-4-0 .fp-right#fp-nav ul li a.active::after,
.fp-viewing-4-1 .fp-right#fp-nav ul li a.active::after,
.fp-viewing-4-2 .fp-right#fp-nav ul li a.active::after,
.fp-viewing-4-3 .fp-right#fp-nav ul li a.active::after,
.fp-viewing-4-4 .fp-right#fp-nav ul li a.active::after,
.fp-viewing-4-5 .fp-right#fp-nav ul li a.active::after,
.fp-viewing-4-6 .fp-right#fp-nav ul li a.active::after,
.fp-viewing-4-7 .fp-right#fp-nav ul li a.active::after {
  color: whitesmoke;
}

.fp-viewing-0 .fp-right#fp-nav ul li a.active,
.fp-viewing-4-0 .fp-right#fp-nav ul li a.active,
.fp-viewing-4-1 .fp-right#fp-nav ul li a.active,
.fp-viewing-4-2 .fp-right#fp-nav ul li a.active,
.fp-viewing-4-3 .fp-right#fp-nav ul li a.active,
.fp-viewing-4-4 .fp-right#fp-nav ul li a.active,
.fp-viewing-4-5 .fp-right#fp-nav ul li a.active,
.fp-viewing-4-6 .fp-right#fp-nav ul li a.active,
.fp-viewing-4-7 .fp-right#fp-nav ul li a.active {
  border: 3px solid whitesmoke;
}

/* Mobile devices */
@media only screen and (max-width: 768px) {
  .fp-right#fp-nav {
    display: none;
  }
}

/* small screen */
@media screen and (min-width: 768px) and (max-width: 1919px) {
  .fp-right#fp-nav ul li {
    margin: 36px 7px;
  }
}

/* mid and big screen */
@media screen and (min-width: 1920px) {
  .fp-right#fp-nav ul li a.active::after {
    left: 1.3px;
  }
}
