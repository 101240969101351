@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&family=Outfit:wght@400;500;600;700&family=Spline+Sans:wght@400;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0 0 24px 0;
}

a,
button {
  transition: 0.1s;
  cursor: pointer;
}

textarea {
  font-family: 'Work Sans', sans-serif;
}

textarea,
input.text,
input[type='text'],
input[type='button'],
input[type='submit'],
input[type='number'],
.input-checkbox {
  border-radius: 4px;
}

.bold {
  font-weight: bold;
}

.montserrat {
  font-family: 'Montserrat', sans-serif;
}

h1,
h2,
b {
  font-weight: 700;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
}
