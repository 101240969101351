@keyframes appear {
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.widget-align-right {
  @media screen and (max-width: 760px) {
    margin-bottom: 50px;
  }
}

.delay {
  animation-duration: 3s;
  animation-name: appear;
  animation-iteration-count: 1;
  animation-delay: 4s;
  animation-fill-mode: both;
}
